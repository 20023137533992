<template>
	<div class="fuwu">
		<header-view></header-view>
		<div class="top">HeardLy Terms of Service</div>
		<div class="content">Valid as of July 19, 2024</div>
		<div class="title">1. Object and Validity</div>
		<div class="content">
			1.The company<br><br>HEARD（HK）LIMITED，UNIT 1，9/F，WO HING<br>Address COMMERCIAL BUILDING， 11 WING<br>WO
			STREET,
			CENTRAL. HONG KONG<br>Authorized Managing Director: Jayson Meng<br>Email:jm@heard.ly<br>Recorded in the
			Commercial Register of the Hong Kong Special Administrative Region under
			Commercial Register Number: 75654418-000-08-23-9<br><br>(hereinafter referred to as "HeardLy") operates a
			platform for mobile reading and listening on the internet portals http://www.heard.ly and other websites, as
			well as on mobile applications (a.k.a. Apps)(hereinafter
			referred to as "Platform"). In this context, HeardLy’s authors transform the key insights of non-fiction
			books and other content for you into a concise format. This gives you the opportunity to discover new books
			through delivering the key insights from non-fiction books or other content on your computer or mobile
			device. In addition, you have the possibility to purchase content on the Platform for download into your
			HeardLy account library.<br><br>2.The use of the Platform shall be subject to the following General Terms
			and
			Conditions ("General Terms and Conditions") in the version valid at the time of your registration
			respectively order. HeardLy does not acknowledge any different general terms and conditions belonging to
			you unless HeardLy agrees to their validity in writing.<br><br>3.No separate contract text is provided for
			the
			agreement pertaining to the use of the Platform. The content of the agreement between you and HeardLy is
			based on these General Terms and Conditions, as well as on the specific information provided to you as part
			of the registration (see Section 3), when ordering a paid subscription (Section 4) and/or audiobooks or
			other content (see Section 6), that will be saved by HeardLy and that is available in your user account at
			any time.<br><br>4.You accept the validity and applicability of these General Terms and Conditions when
			registering, purchasing a paid subscription or audiobooks, as the case may be.
		</div>
		<div class="title">2. HeardLy's Services and Rights</div>
		<div class="content">1.HeardLy provides the key insights from non-fiction books, articles and other content
			(e.g., the "books-in-blinks" or “articles-in-blinks”, hereinafter jointly referred to as ”Platform
			Content”), which allow you to grasp their essence within a few minutes. Moreover, HeardLy may provide a
			personal profile to you, which you can fill with your preferred platform content and text markers as well as
			sorting this content into different categories and lists. In addition, you can purchase audiobooks and other
			premium audio content (“Audiobook Content”) on the Platform.<br><br>2.The extent of the platform Content and
			the
			options to use (the “License”) depend on the type of account you selected. There is a difference between a
			free account (see Section 3), a paid subscription (see Section 4) and a free trial (see Section 5) and a
			purchase of Audiobook Content (see Section 6). In terms of duration, the license is limited to the validity
			period of the free account / paid subscription / free trial, however, in case of a purchase of Audiobook
			Content availability in your account will be, subject to Section 6, unlimited.<br><br>3.The rights to
			HeardLy's
			service and the content distributed through the service are and will remain with HeardLy and /or HeardLy's
			licensors. The HeardLy software applications and content are licensed to you within the scope of the
			contractual use. We only grant you a non-exclusive (simple), time-limited right to personal use of the
			HeardLy service and its contents (however, subject to Section 6, availability of purchased Audiobook
			Content will be unlimited in your account).<br><br>4.All HeardLy trademarks as well as our trade names,
			logos,
			domain names and other distinctive features of the HeardLy trademark are the sole property of HeardLy. The
			license does not give you any right to use the HeardLy trademarks, our trade names, logos, domain names or
			any other distinctive features of the HeardLy trademark, be it for commercial or non-commercial
			purposes.<br><br>5.If you do not use a paid service, HeardLy is allowed to show you promotional information
			on
			the Platform.</div>
		<div class="title">3. Registering with HeardLy</div>
		<div class="content">1.In order to be able to use HeardLy’s services, a registration with HeardLy is
			required. Individuals over the age of 18 have the right to register. As a minor, you may only register with
			HeardLy if you are at least 14 years old and your legal representatives have provided their consent.
			HeardLy reserves the right to make the use of HeardLy services contingent on proper proof of your
			identity, your legal age, or your legal representative’s consent.<br><br>2.The registration itself is free
			of charge (free account) and does not oblige you to obtain a paid subscription. By registering, you are
			executing an agreement with HeardLy for a limited, free use of the Platform.<br><br>3.You can use your
			e-mail address as well as a password of your choice to register. Alternatively, you may register using a
			single sign-on service (for example, Apple ID login). You can register via our website or after downloading
			our iOS or Android apps.<br><br>4.Any information required for registration must be complete
			and correct and always up-to-date. HeardLy has the right to save and process the data you provided during
			registration, in accordance with the provisions on Data Protection.<br><br>5.The password used must be kept
			confidential. Keeping it a secret is solely and fully your responsibility. The HeardLy user account may
			only be used by you. Any unauthorized use of your user account, as well as any such suspicion to that effect
			should be communicated to HeardLy immediately.</div>
		<div class="title">4. Executing an Agreement for a Paid Subscription</div>
		<div class="content">1.To be able to fully benefit from HeardLy's services, additional service packages in the
			form of paid subscriptions (“Subscription Access”) are available to you after registering. These can be
			purchased for different, and variable periods of time, which you will see in the order
			options.<br><br>2.HeardLy's Subscription Accesses are ad-free, paid subscriptions that allow you to access
			additional content and features through our mobile and web-based applications. We currently offer the
			following subscription / service package:<li>The HeardLy Premium service package includes ad-free access to
				all of our Platform Content in both text and audio.</li>You will have access to the respective services
			as long as your
			subscription is valid.<br><br>3.You submit a legally binding offer for a fee-based subscription, if you
			enter the information requested in the online order form and then click on the “Order with obligation to
			pay” button. By doing so, you submit a legally binding offer for an agreement for a paid subscription.
			Before submitting your order, you may change and div the data at any time.<br><br>4.The contract is
			concluded when HeardLy issues a notice of acceptance (order confirmation), which will be sent to you by
			email within a reasonable time. If you purchased your fee-based subscription via HeardLy’s web-based
			application HeardLy will send the order confirmation to you by email together with the receipt confirmation
			immediately following your order. If you purchased your fee-based subscription via an in-app purchase
			(please also see subsection 5) you will receive a receipt confirmation following your order from the third
			party appstore and HeardLy will send to you the notice of acceptance (order confirmation) by separate
			email. In both cases, the text of the contract (consisting of the order, the General Terms and Conditions
			and the order confirmation) will be sent to you by HeardLy on a permanent data carrier (email) (contract
			confirmation). Your subscription begins on this date. The text of the agreement is saved subject to data
			protection. Please read our Privacy Statement for information on the collection, use and processing of your
			data.<br><br>5.You can also sign up for a paid subscription through an in-app purchase through our iOS or
			Android apps. In order to do this, you must select the desired option on the subscription screen within the
			app, and a pop-up will appear where you have to enter your password for the third party appstore. As soon as
			you finish, a pop-up will appear in which you have to confirm the purchase of the subscription again. At
			this point you can still cancel the process. You should then receive a receipt confirmation from the third
			party appstore (not directly from HeardLy).</div>
		<div class="title">5. Trials/ Testing Phases</div>
		<div class="content">1.HeardLy occasionally offers in particular to new users free Subscription Access for
			testing purposes for a certain period of time (“Trial”). HeardLy determines, at its sole discretion, if you
			may participate in a Trial. In case of an important reason, HeardLy may discontinue or change such free
			Trial at any time without prior notice or notification.<br><br>2.You can only participate in certain Trials
			if you enter your payment details when you sign up for the Trial. In such a case, we need your consent at
			the beginning of the Trial that your free access will be converted into a paid Subscription Access (Section
			4) on the day following the end of the Trial. In this situation, your subscription renews itself on a
			recurring basis and can be terminated up to one day before the end of the respective subscription period
			(Section 8.2).<br><br>3.If, in a case of Section 5.2 , you do not wish to have the Subscription Access and
			the charge associated with it, you must cancel your Subscription Access given to you during the Trial before
			the end of the Trial (for example, by simply going to your HeardLy Account Settings or the respective app
			store settings).</div>
		<div class="title">6. Audiobooks and Credits</div>
		<div class="content">1.If you registered with HeardLy, HeardLy allows you to purchase Audiobook Content
			through the Platform. Purchases can be made on an à la carte basis. Paid Subscription Access is not
			necessary in order to purchase Audiobook Content, however HeardLy will often offer more attractive pricing
			for Audiobook Content for customers who have Subscription
			Access.<br><br>2.You can also buy Audiobook Content through an in-app purchase through our
			iOS or Android apps. In order to do this, you must select the desired option on the purchase screen within
			the app where you have to log-in and confirm the purchase of the Audiobook Content. At this
			point you can still cancel the process. You should then receive a receipt confirmation from the third party
			appstore (not directly from HeardLy).<br><br>3.HeardLy reserves the right, in its sole discretion, to
			change prices of audiobooks, at any time.<br><br>4.In the event HeardLy provided you with free
			Audiobook Content in connection with a Subscription Access, and you decide to cancel your Subscription
			Access, HeardLy may remove such free Audiobook Content granted in connection with Subscription Access from
			your HeardLy Library.<br><br>5.HeardLy in its absolute and sole discretion, reserves the right to refuse
			orders for Audiobook Content placed through the Platform, and – taking into account the particular
			circumstances of the case – in the event of fraud or abuse by a user, the right to temporarily block the
			access or to remove the Audiobook Content available in the library of that user.<br><br>6.When you purchase
			Audiobook Content, HeardLy grants you a non-exclusive, non-transferable, non-assignable, non-sublicensable
			license to download such content to your HeardLy Library for your personal, non-commercial use. You are not
			entitled to alter, modify, sell, transfer, share, lease, decompile, disassemble, reverse engineer, copy,
			reproduce, duplicate, distribute, publicly perform, create derivative works from or otherwise commercially
			exploit the Audiobook Content or otherwise use and/or export the Audiobook Content outside the HeardLy
			Library. Any unauthorized transfer, duplication, or exploitation of any Audiobook Content constitutes
			copyright infringement, breach of contract, and potential other violations and liabilities, and is subject
			to civil and criminal penalties.<br><br>7.Purchased Audiobook Content will generally continue to be
			available in your "Library" but may become unavailable through no fault of HeardLy due to the unlikely
			event of potential new licensing restrictions of the content providers or other new requirements of the
			content providers. HeardLy will not be liable to you if Audiobook Content becomes unavailable for further
			download for such reasons.</div>
		<div class="title">7. Cancellation Policy</div>
		<div class="content">1.If you are a consumer (i.e., a person completing the legal transaction for purposes that
			can be predominantly attributed to neither their commercial nor their independent professional activity),
			you are entitled to a statutory withdrawal right when concluding a distance selling agreement, about which
			HeardLy provides information below in accordance with the statutory model. Please note that in the case of
			an in-app purchase, you have a right of withdrawal within the respective app store, i.e. Apple-App-Store or
			Google Play Store. A model withdrawal form can be found in Section 7.3.<br><br>
			- BEGIN INSTRUCTIONS ON WITHDRAWAL -<br><br>
			Right of withdrawal<br><br>
			You have the right to withdraw from this contract within 14 days without giving any reason. The withdrawal
			period will expire after 14 days from the day of the conclusion of the contract.<br><br>
			To exercise the right of withdrawal, you must inform us<br><br>
			E-mail: jm@heard.ly<br><br>
			of your decision to withdraw from this contract by an unequivocal statement (e.g. a letter sent by post, fax
			or e-mail). You may use the attached model withdrawal form, but it is not obligatory.
			<br><br>To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your
			exercise of the right of withdrawal before the withdrawal period has expired.<br><br>
			Effects of withdrawal<br><br>
			If you withdraw from this contract, we shall reimburse to you all payments received
			from you, including the costs of delivery (with the exception of the supplementary costs resulting from your
			choice of a type of
			delivery other than the least expensive type of standard delivery offered by us) without undue delay and in
			any event not later than 14 days from the day on which we are informed about your decision to withdraw from
			this contract. We will carry out such reimbursement using the same means of payment as you used for the
			initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees
			as a result of such reimbursement.<br><br>
			If you requested to begin the performance of services during the
			withdrawal period, you shall pay us an
			amount which is in proportion to what has been provided until you have communicated us your withdrawal from
			this contract, in comparison with the full coverage of the contract.<br><br>
			- END INSTRUCTIONS ON WITHDRAWAL -<br><br>
			The right of withdrawal expires with regard to Audiobook Content contracts if the performance has begun
			(i.e. you downloaded the Audiobook Content to your HeardLy Library) with your prior consent before the end
			of the withdrawal period and your acknowledgment that you thereby lose your right of
			withdrawal.<br><br>
			According to the legal provision, HeardLy states the following about the sample cancellation
			form:<br><br>
			Sample Cancellation Form<br><br>
			(Please fill out this form and send it back if you wish to cancel the
			Agreement.)<br><br>
			– To: jm@heard.ly
			– I hereby revoke the Agreement executed by me relating to the purchase of the following products (*)/the
			following service (*) provided
			– Ordered on (*)/received on (*)<br>
			– Consumer name(s)<br>
			– Consumer address<br>
			– Consumer signature (only if notification is on paper)<br>
			– Date<br>
			(*) Cross out if it does not apply.
		</div>
		<div class="title">8. Reimbursement and Payment Methods, Compensation and Retention Rights</div>
		<div class="content">1.The conclusion of a subscription for Subscription Access (Section 4) is subject to a fee
			and must be paid at the beginning of the subscription. The purchase of Audiobooks is also subject to
			payment.
			<br><br>2.As payment methods, HeardLy offers credit card and additional payment methods, if available,
			specified before beginning of the order process.
			<br><br>3.When purchasing a subscription with the HeardLy iOS or Android apps, payment is made by Apple's or
			Google's in-app purchase process.
			<br><br>4.As part of your Subscription Access, your payment obligation to HeardLy will be automatically
			renewed at the end of the relevant subscription term if you do not cancel your paid subscription at least 24
			hours before the end of the term (Section 9.2).
			<br><br>5.You are not entitled to offset any claims against claims of HeardLy, unless your counterclaims are
			finally determined with legal effect or undisputed. You are also entitled to offset against the claims of
			HeardLy, if you make complaints for defects or counterclaims based on the same agreement.
			<br><br>6.You may only exercise your right of retention if your counterclaim results from the same
			agreement.
		</div>
		<div class="title">9. Term and Cancellation</div>
		<div class="content">1.Term of the free usage agreement: The free usage agreement starts from the time you
			register according to Section 3 of these Terms of Service and runs indefinitely. You may cancel the free
			usage agreement at any time. To this effect, you can delete your account via our web app in the "Settings"
			menu item.
			<br><br>2.The Subscription Access cancellation takes effect on the day following the last day of your
			current
			subscription period and you will be downgraded to the free user agreement (free account). However, once you
			renew a subscription again, you will be able to access your old status and reading lists. If you wish to
			delete your entire account, you can do so from the settings in our web apps (see Section 8.3). All data will
			be deleted irrevocably.
			<br><br>3.The right to blocking and cancellation for an important reason due to a good cause remains
			unaffected by the preceding provisions.
		</div>
		<div class="title">10. Gift Certificates and Special Offers</div>
		<div class="content">1.If you are the holder of a HeardLy gift certificate that allows the use of a HeardLy
			Premium service package for the period mentioned in the accompanying email, card or paper receipt, you have
			the right to use the HeardLy Platform during the respective period of time.</div>
		<div class="title">11. Liability</div>
		<div class="content">1. HeardLy disqualifies any claims for damages coming from you. This does not apply to
			claims for damages arising from fatal injuries, physical injuries, health damage or from the breach of
			essential contractual obligations (cardinal obligations) as well as liability for other damages that are
			based on an intentional or grossly negligent breach of duty by . HeardLy, its legal representatives or
			affiliates. Essential contractual obligations are those the fulfillment of which enables the proper
			execution of the Agreement in the first place and on the fulfillment of which you can trust.
			<br><br>2.In the event of a breach of essential contractual obligations, . HeardLy shall only be liable for
			the typical, foreseeable damages stipulated in a contract, if they were simply caused by negligence, unless
			it also involves claims for damages due to fatal injuries, physical injuries or damages to health.
			<br><br>3.The restrictions mentioned in Section 10.1 and 10.2 also apply to breaches of duty by . HeardLy’s
			affiliates and legal representatives, if claims are made directly against them.
		</div>
		<div class="title">12. Change of Terms and Conditions</div>
		<div class="content">1.HeardLy reserves the right to amend and/or supplement the General Terms and Conditions
			with effect in the future, provided that this is reasonable for you taking into account . HeardLy’s
			interests. If no explicit consent is obtained from you to adapt the General Terms and Conditions, you will
			be notified by . HeardLy of any amendments and/or supplements to the terms and conditions in a timely manner
			(at least four weeks before the amended General Terms and Conditions enter into force). To this end, .
			HeardLy will send you the new version of the General Terms and Conditions to the e-mail address specified by
			you in the registration. In this context, . HeardLy will expressly point out to you the possibility and
			deadline for an objection to the new version of the General Terms and Conditions, as well as the
			consequences, should you not object.
			<br><br>2.If you do not object to the applicability of the new version of the General Terms and Conditions
			within four
			weeks after receipt of the notification referred to in paragraph (1) of this Section, the new version of the
			GTC shall be deemed accepted by you.
			<br><br>3.If you object to the new version of the General Terms and Conditions in due time, the contractual
			relationship shall be continued under the previous General Terms and Conditions, but . HeardLy may decide to
			terminate the free user agreement or the paid subscription with effect from the next possible regular
			termination date.
		</div>
		<div class="content"><br>By using our products and services, you agree to join the mailing list of the
			MindHub
			Newsletters, provided by Cubo World Inc for Heardly. Please note that your email address will be shared
			with Cubo World Inc. via API integration solely for the purpose of delivering newsletters. Your personal
			and sensitive information will not be shared. You have the option to unsubscribe from the mailing list
			at any time by following the instructions provided in the newsletters.</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		methods: {},
		mounted() {

		}
	}
</script>

<style lang="less" scoped>
	.fuwu {
		padding: 87px 15px 40px;

		.top {
			font-size: 28px;
			font-weight: 600;
			padding: 25px 0;
		}

		.title {
			font-size: 20px;
			margin: 20px 0;
		}

		.content {
			font-size: 13px;

			li {
				margin: 10px 0;
			}
		}
	}
</style>